import React, { useContext, useState } from "react";
import style from "../NewUI/NewUI.module.css";
import { StoreContext } from "../../data/Context";
import { ChatMessageType, ChatType } from "../NewUI/NewChatAndSidebar";

type AiMessagePropsType = {
  index: number;
  message: ChatMessageType;
  generatingInProgress: boolean;
  setRatingToMessage: (rating: string, id: string | undefined) => void;
  setCommentToMessage: (
    comment: string | undefined,
    id: string | undefined
  ) => void;
  currentChat: ChatType;
};

const AiMessage: React.FC<AiMessagePropsType> = ({
  index,
  message,
  generatingInProgress,
  setRatingToMessage,
  setCommentToMessage,
  currentChat,
}) => {
  const state = useContext(StoreContext);

  const [showCommentArea, setShowCommentArea] = useState<boolean>(false);
  const [commentText, setCommentText] = useState<string | undefined>(
    message.comment
  );

  const setCommentHandler = () => {
    setShowCommentArea(false);
    setCommentToMessage(commentText, message.chat_message_id);
  };

  return (
    <div
      key={index}
      className="message-wrapper"
      style={{ justifyContent: "flex-start" }}
    >
      <div
        className={style.gIconTemp}
        style={{ backgroundColor: state.store.config.colorPrimary }}
      >
        G
      </div>
      <div key={index} className={`speech speech-ai`}>
        {message.text !== "" ? (
          <>
            <p className={style.userIntentText}>
              {message.intent !== "None" && message.intent}
            </p>
            <div
              className="answer-box"
              style={{ color: "grey", fontWeight: 600 }}
              dangerouslySetInnerHTML={{ __html: message.text }}
            />
            {!generatingInProgress && (
              <div
                className={
                  index === currentChat.chat_content.length - 1
                    ? style.messageLikeDislikeEditWrapper
                    : "messageLikeDislikeEditWrapperNotLast"
                }
                style={showCommentArea ? { display: "flex" } : undefined}
              >
                <div className={style.ratingCommentBlockInnerWrapper}>
                  <button
                    className={
                      message.rating === "like"
                        ? style.messageLikeDislikeEditButtonActive
                        : style.messageLikeDislikeEditButton
                    }
                    onClick={() =>
                      setRatingToMessage("like", message.chat_message_id)
                    }
                  >
                    <img
                      src="/images/thumbs-up.svg"
                      width={18}
                      height={18}
                      alt="like"
                    />
                  </button>
                  <button
                    className={
                      message.rating === "dislike"
                        ? style.messageLikeDislikeEditButtonActive
                        : style.messageLikeDislikeEditButton
                    }
                    onClick={() =>
                      setRatingToMessage("dislike", message.chat_message_id)
                    }
                  >
                    <img
                      src="/images/thumbs-down.svg"
                      width={18}
                      height={18}
                      alt="dislike"
                    />
                  </button>
                  <button
                    className={style.messageLikeDislikeEditButton}
                    onClick={() => setShowCommentArea(!showCommentArea)}
                  >
                    <img
                      src="/images/edit.svg"
                      width={18}
                      height={18}
                      alt="edit"
                    />
                  </button>
                  {showCommentArea && (
                    <div
                      className={style.ratingCommentTextareaWrapper}
                      style={{
                        borderColor: state.store.config.colorPrimary,
                      }}
                    >
                      <textarea
                        className={style.ratingCommentTextarea}
                        rows={7}
                        cols={30}
                        value={commentText}
                        onChange={(e) => setCommentText(e.currentTarget.value)}
                      ></textarea>
                      <button
                        className={style.createNewTemplateButton}
                        style={{
                          backgroundColor: state.store.config.colorPrimary,
                        }}
                        onClick={setCommentHandler}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="loader" />
        )}
      </div>
    </div>
  );
};

export default AiMessage;
