import React, { useContext, useEffect, useState } from "react";
import style from "./AdminPage.module.css";
import axios from "axios";
import { AccessLevelType } from "./DataUploadPage";
import { AccessLevelEditModeType } from "./GroupItem";
import { useParams } from "react-router-dom";
import { StoreContext } from "../../data/Context";

type AccessControlModalProps = {
  show: boolean;
  handleClose: () => void;
  availableDocs: string[] | undefined;
  availableDocsRole:
    | { role_name: string; role_available_docs: string[] }
    | undefined;
  accessLevels: AccessLevelType[];
  groupId: string | undefined;
  refreshData: () => void;
  accessLevelEditMode: AccessLevelEditModeType;
};

const AccessControlModal: React.FC<AccessControlModalProps> = ({
  show,
  handleClose,
  availableDocs,
  accessLevels,
  groupId,
  refreshData,
  availableDocsRole,
  accessLevelEditMode,
}) => {
  const state = useContext(StoreContext);

  const serverURL = process.env.REACT_APP_SERVER_URL;
  const { siteId } = useParams();

  const [filteredAccessLevelGroups, setFilteredAccessLevelGroups] = useState<
    AccessLevelType[]
  >([]);
  const [filteredRoleAndGroupDocs, setFilteredRoleAndGroupDocs] = useState<
    string[] | undefined
  >(undefined);

  const compareDocsAndSources = () => {
    let tempAccessLevels = [...accessLevels];
    availableDocs?.forEach((doc) => {
      tempAccessLevels = tempAccessLevels.filter(
        (accessLevel) => accessLevel.access_level_name !== doc
      );
    });
    setFilteredAccessLevelGroups(tempAccessLevels);
  };

  const compareRoleDocsAndGroupDocs = () => {
    const groupDocs = availableDocs || [];
    const roleDocs = availableDocsRole?.role_available_docs || [];

    let tempGroupDocs = [...groupDocs];
    roleDocs.forEach((doc) => {
      tempGroupDocs = tempGroupDocs.filter((groupDoc) => groupDoc !== doc);
    });
    setFilteredRoleAndGroupDocs(tempGroupDocs);
  };

  useEffect(() => {
    accessLevelEditMode === "toGroup"
      ? compareDocsAndSources()
      : compareRoleDocsAndGroupDocs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableDocs, accessLevels, availableDocsRole]);

  const addAvailableDocumentGroup = async (
    groupId: string | undefined,
    access_level_group_name: string
  ) => {
    try {
      await axios.post(
        `${serverURL}/${siteId}/add-access-level-group-for-group`,
        {
          group_id: groupId,
          access_level_group_name: access_level_group_name,
        }
      );
      refreshData();
    } catch (error) {
      console.log("Error add available doc - " + error);
    }
  };

  const removeAvailableDocumentGroup = async (
    groupId: string | undefined,
    access_level_group_name: string
  ) => {
    try {
      await axios.post(
        `${serverURL}/${siteId}/remove-access-level-group-for-group`,
        {
          group_id: groupId,
          access_level_group_name: access_level_group_name,
        }
      );
      refreshData();
    } catch (error) {
      console.log("Error remove available doc - " + error);
    }
  };

  const addAvailableDocumentRole = async (docsToAddName: string) => {
    try {
      const res = await axios.post(
        `${serverURL}/${siteId}/add-documents-group-to-role`,
        {
          group_id: groupId,
          role_name: availableDocsRole?.role_name,
          docs_to_add_name: docsToAddName,
        }
      );
      console.log("Add document to role - " + res.data.result);
      refreshData();
    } catch (error) {
      console.error(error);
    }
  };

  const removeAvailableDocumentRole = async (docsToRemoveName: string) => {
    try {
      const res = await axios.post(
        `${serverURL}/${siteId}/remove-documents-group-to-role`,
        {
          group_id: groupId,
          role_name: availableDocsRole?.role_name,
          docs_to_add_name: docsToRemoveName,
        }
      );
      console.log("Add document to role - " + res.data.result);
      refreshData();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={`${style.modal} ${show ? style.show : ""}`}>
      <div className={style.modalContent}>
        <span className={style.close} onClick={handleClose}>
          &times;
        </span>
        {accessLevelEditMode === "toGroup" ? (
          <>
            <h2>Available documents groups</h2>
            <div className={style.sourcesWrapper}>
              {availableDocs?.length !== 0 ? (
                availableDocs?.map((docName: string, index: number) => (
                  <div
                    className={style.uploadedFileWrapper}
                    style={{
                      borderRightColor: state.store.config.colorPrimary,
                    }}
                    key={index}
                  >
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 5,
                      }}
                    >
                      <img
                        style={{ height: 20 }}
                        src="/images/file-zipper.svg"
                        alt="file"
                      />
                      {docName}
                    </p>
                    <button
                      onClick={() =>
                        removeAvailableDocumentGroup(groupId, docName)
                      }
                      className={style.sourceDeleteButton}
                    >
                      <img
                        style={{ height: 30 }}
                        src="/images/minus-square.svg"
                        alt="remove"
                      />
                    </button>
                  </div>
                ))
              ) : (
                <p>No groups available</p>
              )}
            </div>
            <h2>Groups to add</h2>
            <div className={style.sourcesWrapper}>
              {filteredAccessLevelGroups.length !== 0 ? (
                filteredAccessLevelGroups.map(
                  (accessLevelGroup: AccessLevelType, index: number) => (
                    <div className={style.uploadedFileWrapper} key={index}>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 5,
                        }}
                      >
                        <img
                          style={{ height: 20 }}
                          src="/images/file-zipper.svg"
                          alt="file"
                        />
                        {accessLevelGroup.access_level_name}
                      </p>
                      <button
                        onClick={() =>
                          addAvailableDocumentGroup(
                            groupId,
                            accessLevelGroup.access_level_name
                          )
                        }
                        className={style.sourceDeleteButton}
                      >
                        <img
                          style={{ height: 30 }}
                          src="/images/plus-square.svg"
                          alt="add"
                        />
                      </button>
                    </div>
                  )
                )
              ) : (
                <p>No groups to add</p>
              )}
            </div>
          </>
        ) : accessLevelEditMode === "toRole" ? (
          <>
            <h2>Verfügbare Dokumente für diese Rolle</h2>
            <div className={style.sourcesWrapper}>
              {availableDocsRole?.role_available_docs.length !== 0 ? (
                availableDocsRole?.role_available_docs.map(
                  (docName: string, index: number) => (
                    <div
                      className={style.uploadedFileWrapper}
                      style={{
                        borderRightColor: state.store.config.colorPrimary,
                      }}
                      key={index}
                    >
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 5,
                        }}
                      >
                        <img
                          style={{ height: 20 }}
                          src="/images/file-zipper.svg"
                          alt="file"
                        />
                        {docName}
                      </p>
                      <button
                        onClick={() => removeAvailableDocumentRole(docName)}
                        className={style.sourceDeleteButton}
                      >
                        <img
                          style={{ height: 30 }}
                          src="/images/minus-square.svg"
                          alt="remove"
                        />
                      </button>
                    </div>
                  )
                )
              ) : (
                <p>No groups available</p>
              )}
            </div>
            <h2>Gruppen einer Rolle hinzufügen</h2>
            <div className={style.sourcesWrapper}>
              {filteredRoleAndGroupDocs?.length !== 0 ? (
                filteredRoleAndGroupDocs?.map(
                  (accessLevelGroup: string, index: number) => (
                    <div
                      className={style.uploadedFileWrapper}
                      style={{
                        borderRightColor: state.store.config.colorPrimary,
                      }}
                      key={index}
                    >
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 5,
                        }}
                      >
                        <img
                          style={{ height: 20 }}
                          src="/images/file-zipper.svg"
                          alt="file"
                        />
                        {accessLevelGroup}
                      </p>
                      <button
                        onClick={() =>
                          addAvailableDocumentRole(accessLevelGroup)
                        }
                        className={style.sourceDeleteButton}
                      >
                        <img
                          style={{ height: 30 }}
                          src="/images/plus-square.svg"
                          alt="add"
                        />
                      </button>
                    </div>
                  )
                )
              ) : (
                <p>No groups to add</p>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AccessControlModal;
