import React, { useState } from "react";
import style from "./NewUI.module.css";
import { ChatType } from "./NewChatAndSidebar";
import { PromptTemplate } from "@langchain/core/prompts";
import { StringOutputParser } from "@langchain/core/output_parsers";
import { llmOpenAI } from "../../langchain/chains";
import { mockData, mockKeys } from "../../data/mock";

const initialMessage: ChatType = {
  chat_id: "123",
  chat_name: "Chat Room",
  user_id: "user1",
  chat_content: [],
  last_changed: "",
  role: "user",
};

type CustomerType = {
  Name: string;
  Vorname: string;
  Gliederung: string;
  Mitgliedschaft: string;
  "Letzter Kontakt": string;
  Grund: string;
  Geburtstag: string;
  Firma: string;
  "e-Mail": string;
};

const AskCRMMembers = () => {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [currentChat, setCurrentChat] = useState(initialMessage);
  const [userInput, setUserInput] = useState("");
  const [filteredCustomers, setFilteredCustomers] = useState<CustomerType[]>(
    []
  );

  const filterData = (data: any, filters: any) => {
    return data.filter((item: any) => {
      return Object.keys(filters).every((key) => {
        // eslint-disable-next-line eqeqeq
        return item[key] == filters[key];
      });
    });
  };
  const filterOutIndex = (data: CustomerType[], indexToRemove: number) => {
    setFilteredCustomers(data.filter((_, index) => index !== indexToRemove));
  };

  const checkDoAnotherFiltering = async (question: string) => {
    const prompt = `Analyze user input and try to catch words that means that User want's to do another customer finding. Sometimes user can ask question to already filtered customers and it can look like user want's to filter again, so be careful. Return only true or false. Answer object will be used automaticaly for next javascript function. Remove "json" or "js" code block from the answer. 
            Question: {question}    
        `;
    const promptText = PromptTemplate.fromTemplate(prompt);

    const answer_chain_find_filter_values = promptText
      .pipe(llmOpenAI)
      .pipe(new StringOutputParser());

    const resRaw = await answer_chain_find_filter_values.invoke({
      question: question,
    });

    const removeJSBlock = resRaw
      .replace(/```javascript/g, "")
      .replace(/```/g, "")
      .trim();

    const removeJSONBlock = removeJSBlock
      .replace(/```json/g, "")
      .replace(/```/g, "")
      .trim();

    const jsObject = JSON.parse(removeJSONBlock);

    // console.log(jsObject);
    return jsObject;
  };

  const analyzeData = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setInProgress(true);
    setUserInput("");

    const prompt = `Analyze user input and try to catch values for filter: {userInput}. If user did not wrote filter group name, try to think and assign catched value to available filter groups. Available filter groups: {keys}. Answer must contain only Javascript object with keys from filter group and catched values from user input. Value in object must be in german language. Answer object will be used automaticaly for next javascript function. Remove "json" or "js" code block from the answer`;
    const promptText = PromptTemplate.fromTemplate(prompt);

    const answer_chain_find_filter_values = promptText
      .pipe(llmOpenAI)
      .pipe(new StringOutputParser());

    const resRaw = await answer_chain_find_filter_values.invoke({
      userInput: userInput,
      keys: mockKeys.join(" "),
    });

    // console.log(resRaw);
    const removeJSBlock = resRaw
      .replace(/```javascript/g, "")
      .replace(/```/g, "")
      .trim();

    // console.log(removeJSBlock);
    const removeJSONBlock = removeJSBlock
      .replace(/```json/g, "")
      .replace(/```/g, "")
      .trim();

    // console.log(removeJSONBlock);
    const jsObject = JSON.parse(removeJSONBlock);
    console.log(jsObject);

    const filteredCustomerInfo = filterData(mockData, jsObject);
    console.log(filteredCustomerInfo);

    setFilteredCustomers(filteredCustomerInfo);

    const structurizationPrompt = `Write structurized text about member (Mitglieder) or members from our CRM system based on provided information: {info}. Answer must be in German Language. Wrap answer with HTML-elements, do not wrap with html block`;
    const structurizationPromptText = PromptTemplate.fromTemplate(
      structurizationPrompt
    );

    const structurizationAnswer_chain = structurizationPromptText
      .pipe(llmOpenAI)
      .pipe(new StringOutputParser());

    try {
      setCurrentChat((prevCurrentChat) => ({
        ...prevCurrentChat,
        chat_content: [{ text: "", sender: "ai" }],
      }));
      const response = await structurizationAnswer_chain.stream({
        info: JSON.stringify(filteredCustomerInfo),
      });

      for await (const chunk of response) {
        setCurrentChat((prevCurrentChat) => {
          const updatedChatContent = [...prevCurrentChat.chat_content];
          const lastIndex = updatedChatContent.length - 1;

          updatedChatContent[lastIndex] = {
            ...updatedChatContent[lastIndex],
            text: (updatedChatContent[lastIndex].text || "") + chunk,
          };

          return {
            ...prevCurrentChat,
            chat_content: updatedChatContent,
          };
        });
      }
      setInProgress(false);
    } catch (error) {
      setInProgress(false);
      console.error("Error during data analysis:", error);
      setCurrentChat((prevCurrentChat) => ({
        ...prevCurrentChat,
        chat_content: [
          ...prevCurrentChat.chat_content,
          { text: "Error analyzing data.", sender: "ai" },
        ],
      }));
    }
  };

  const getAnswer = async (question: string) => {
    try {
      const structurizationPrompt = `Write structurized answer about member (Mitglieder) or members from our CRM system based on provided question and customers information. 
                Answer must be in German Language.
                Wrap answer with HTML-elements, do not wrap with html block
                
                Question: {question}
                Customers to work with; {customers}
                `;
      const structurizationPromptText = PromptTemplate.fromTemplate(
        structurizationPrompt
      );

      const structurizationAnswer_chain = structurizationPromptText
        .pipe(llmOpenAI)
        .pipe(new StringOutputParser());

      const response = await structurizationAnswer_chain.stream({
        question: question,
        customers: JSON.stringify(filteredCustomers),
      });
      const chunks = [];

      for await (const chunk of response) {
        chunks.push(chunk);
        setCurrentChat((prevCurrentChat) => {
          const updatedChatContent = [...prevCurrentChat.chat_content];
          const lastIndex = updatedChatContent.length - 1;

          updatedChatContent[lastIndex] = {
            ...updatedChatContent[lastIndex],
            text: (updatedChatContent[lastIndex].text || "") + chunk,
          };

          return { ...prevCurrentChat, chat_content: updatedChatContent };
        });
      }

      return chunks;
    } catch (error) {
      console.log("getAnswerChain error - " + error);
    }
  };

  const progressConversation = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    questionInput: string
  ) => {
    event.preventDefault();
    try {
      setUserInput("");
      setInProgress(true);

      const isAnotherFiltering = await checkDoAnotherFiltering(questionInput);

      if (isAnotherFiltering) {
        await analyzeData(event);
      } else {
        const newMessages = [
          ...currentChat.chat_content,
          { text: questionInput, sender: "human" },
          { text: "", sender: "ai" },
        ];

        const updatedMessagesArray = {
          ...currentChat,
          chat_content: newMessages,
        };

        setCurrentChat(updatedMessagesArray);

        await getAnswer(questionInput);
      }
      setInProgress(false);
    } catch (error) {
      console.log("Error in progressConversation:", error);
      setInProgress(false);
    }
  };

  return (
    <>
      <div className={style.chatWrapper}>
        <div
          className={style.chatMessagesWrapper}
          style={{ height: "100%", maxHeight: "100%" }}
        >
          {currentChat.chat_content.map((message, index) =>
            message.sender === "ai" ? (
              <div
                key={index}
                className="message-wrapper"
                style={{ justifyContent: "flex-start" }}
              >
                <div className={style.gIconTemp}>G</div>
                <div key={index} className={`speech speech-ai`}>
                  {message.text !== "" ? (
                    <div
                      className="answer-box"
                      style={{ color: "grey", fontWeight: 600 }}
                      dangerouslySetInnerHTML={{ __html: message.text }}
                    />
                  ) : (
                    <div className="loader" />
                  )}
                </div>
              </div>
            ) : (
              <div
                key={index}
                className="message-wrapper"
                style={{ justifyContent: "flex-end" }}
              >
                <div key={index} className={`speech speech-human`}>
                  {message.text !== "" ? (
                    <div
                      className="answer-box"
                      style={{ color: "grey", fontWeight: 600 }}
                      dangerouslySetInnerHTML={{ __html: message.text }}
                    />
                  ) : (
                    <div className="loader" />
                  )}
                </div>
              </div>
            )
          )}
        </div>

        <form id="form" className={style.inputWrapper}>
          <input
            name="user-input"
            type="text"
            id="user-input"
            required
            value={userInput}
            onChange={(e) => setUserInput(e.currentTarget.value)}
            placeholder="Schreibe eine Nachricht"
            style={{
              borderBottomLeftRadius: 50,
              borderTopLeftRadius: 50,
              backgroundColor: "#d3d3d345",
              borderColor: "#d3d3d345",
            }}
          />
          <button
            disabled={inProgress}
            id="submit-btn"
            className="submit-btn"
            onClick={(e) =>
              filteredCustomers.length === 0
                ? analyzeData(e)
                : progressConversation(e, userInput)
            }
            style={{
              borderBottomRightRadius: 50,
              borderTopRightRadius: 50,
              backgroundColor: "#d3d3d345",
              borderColor: "#d3d3d345",
              cursor: "pointer",
            }}
          >
            <img src="/images/send.svg" className="send-btn-icon" alt="" />
          </button>
        </form>
      </div>
      {filteredCustomers.length !== 0 && (
        <div className={style.tasksLibRightMenuWrapper} style={{ gap: 30 }}>
          {filteredCustomers.length !== 0 &&
            filteredCustomers.map((customer: CustomerType, index: number) => (
              <div className={style.askCRMRightMenuItemsWrapper} key={index}>
                <button
                  className={style.askCRMRightMenuItemButton}
                  onClick={() => filterOutIndex(filteredCustomers, index)}
                >
                  X
                </button>
                <h2>
                  {customer.Vorname} {customer.Name}
                </h2>
                <span>Gliederung: {customer.Gliederung}</span>
                <span>Mitgliedschaft: {customer.Mitgliedschaft}</span>
                <span>Letzter Kontakt: {customer["Letzter Kontakt"]}</span>
                <span>Grund des Kontakts: {customer.Grund}</span>
                <span>Geburtstag: {customer.Geburtstag}</span>
                <span>Firma: {customer.Firma}</span>
                <span>E-Mail: {customer["e-Mail"]}</span>
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default AskCRMMembers;
