import React, { useContext } from "react";
import style from "../NewUI/NewUI.module.css";
import { isToday, isYesterday, isWithinInterval, subDays } from "date-fns";
import { ChatType } from "../NewUI/NewChatAndSidebar";
import { StoreContext } from "../../data/Context";

type SidebarPropsType = {
  chatList: ChatType[];
  selectChat: (chat_id: string) => void;
  currentChatId: string | undefined;
};

const Sidebar: React.FC<SidebarPropsType> = ({
  chatList,
  selectChat,
  currentChatId,
}) => {
  const state = useContext(StoreContext);

  const groupChatsByDate = (chats: ChatType[]) => {
    const today: ChatType[] = [];
    const yesterday: ChatType[] = [];
    const last7Days: ChatType[] = [];
    const last30Days: ChatType[] = [];
    const older: ChatType[] = [];

    const now = new Date();
    const startOfToday = new Date(now.setHours(0, 0, 0, 0));
    const startOfYesterday = subDays(startOfToday, 1);
    const startOfLast7Days = subDays(startOfToday, 7);
    const startOfLast30Days = subDays(startOfToday, 30);

    chats.forEach((chat) => {
      const chatDate = new Date(chat.last_changed);

      if (isToday(chatDate)) {
        today.push(chat);
      } else if (isYesterday(chatDate)) {
        yesterday.push(chat);
      } else if (
        isWithinInterval(chatDate, {
          start: startOfLast7Days,
          end: startOfYesterday,
        })
      ) {
        last7Days.push(chat);
      } else if (
        isWithinInterval(chatDate, {
          start: startOfLast30Days,
          end: startOfLast7Days,
        })
      ) {
        last30Days.push(chat);
      } else {
        older.push(chat);
      }
    });

    return { today, yesterday, last7Days, last30Days, older };
  };

  const groupedChats = groupChatsByDate(chatList);

  const renderChats = (chats: ChatType[], label: any) => {
    if (chats.length === 0) return null;
    return (
      <div key={label} className={style.chatHistoryChatListWrapper}>
        <h3>{label}</h3>
        {chats.map((chat, index) => (
          <span
            key={index}
            className={
              chat.chat_id === currentChatId
                ? style.sidebarItemSelected
                : style.sidebarItem
            }
            style={
              chat.chat_id === currentChatId
                ? {
                    color: state.store.config.colorPrimary,
                    borderRightColor: state.store.config.colorPrimary,
                  }
                : {}
            }
            onClick={() => selectChat(chat.chat_id)}
          >
            {chat.chat_name}
          </span>
        ))}
      </div>
    );
  };

  return (
    <div className={style.chatList}>
      {renderChats(groupedChats.today, "Heute")}
      {renderChats(groupedChats.yesterday, "Gestern")}
      {renderChats(groupedChats.last7Days, "Letzten Woche")}
      {renderChats(groupedChats.last30Days, "Letzten Monat")}
      {renderChats(groupedChats.older, "Older")}
    </div>
  );
};

export default Sidebar;
