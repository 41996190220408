import React, { useContext, useState } from "react";
import style from "./Login.module.css";
import { StoreContext } from "../../data/Context";
import axios from "axios";
import { useParams } from "react-router-dom";

type registrationPropsType = {
  changeComponent: (compNumeber: number) => void;
  navigation: (path: string) => void;
};

const Registration = (props: registrationPropsType) => {
  const serverURL = process.env.REACT_APP_SERVER_URL;

  const { siteId } = useParams();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState<boolean>(false);

  const state = useContext(StoreContext);

  const fetchCreateUser = async () => {
    const userData = {
      username: username,
      email: email,
      password: password,
    };
    try {
      const response = axios.post(`${serverURL}/${siteId}/create-user`, {
        userData,
      });
      return (await response).data.user_id;
    } catch (e) {
      console.log(e);
    }
  };

  const submit = async () => {
    setError(false);
    try {
      const newUserId = await fetchCreateUser();
      state.setStore({
        ...state.store,
        userId: newUserId,
        username: username,
      });
      if (newUserId) {
        await login();
      }
    } catch (error) {
      setError(true);
      console.error(error);
    }
  };

  const login = async () => {
    try {
      const response = await axios.post(`${serverURL}/${siteId}/login`, {
        username,
        password,
      });
      const accessToken = response.data.access_token;
      localStorage.setItem(`${siteId}_access_token`, accessToken);
      props.navigation(`/${siteId}/home`);
    } catch (error) {
      console.error(error);
      localStorage.removeItem(`${siteId}_access_token`);
    }
  };

  const toLogin = () => {
    props.changeComponent(0);
  };
  return (
    <div className={style.registrationContainer}>
      <div className={style.inputWrapper}>
        <div className={style.label}>
          {error ? "Please choose another username" : "Username"}
        </div>
        <input
          style={
            error
              ? { borderColor: "crimson" }
              : { borderColor: state.store.config.colorPrimary }
          }
          className={style.input}
          value={username}
          onChange={(e) => setUsername(e.currentTarget.value)}
          placeholder="Username"
        />
      </div>
      <div className={style.inputWrapper}>
        <div className={style.label}>E-Mail</div>
        <input
          className={style.input}
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          placeholder="E-Mail"
          style={{ borderColor: state.store.config.colorPrimary }}
        />
      </div>
      <div className={style.inputWrapper}>
        <div className={style.label}>Password</div>
        <input
          className={style.input}
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value)}
          placeholder="Password"
          type="password"
          style={{ borderColor: state.store.config.colorPrimary }}
        />
      </div>
      <div className={style.inputWrapper}>
        <div className={style.label}>Confirm password</div>
        <input
          className={style.input}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.currentTarget.value)}
          placeholder="Confirm Password"
          type="password"
          style={{ borderColor: state.store.config.colorPrimary }}
        />
      </div>
      <div className={style.buttonWrapper}>
        <button
          onClick={toLogin}
          className={style.buttonBack}
          style={{ borderColor: state.store.config.colorPrimary }}
        >
          BACK TO LOGIN
        </button>
        <button
          disabled={
            !username ||
            !email ||
            !password ||
            !confirmPassword ||
            password !== confirmPassword
          }
          style={
            !username ||
            !email ||
            !password ||
            !confirmPassword ||
            password !== confirmPassword
              ? {
                  color: state.store.config.colorPrimary,
                  borderColor: state.store.config.colorPrimary,
                }
              : { backgroundColor: state.store.config.colorPrimary }
          }
          onClick={submit}
          className={style.buttonSubmit}
        >
          SUBMIT
        </button>
      </div>
    </div>
  );
};

export default Registration;
