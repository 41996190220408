import React, { useContext, useState } from "react";
import style from "./Login.module.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { StoreContext } from "../../data/Context";

type loginPropsType = {
  changeComponent: (compNumebr: number) => void;
  navigation: (path: string) => void;
};

const Login = (props: loginPropsType) => {
  const serverURL = process.env.REACT_APP_SERVER_URL;
  const { siteId } = useParams();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const state = useContext(StoreContext);

  const login = async () => {
    try {
      const response = await axios.post(`${serverURL}/${siteId}/login`, {
        username,
        password,
      });
      const accessToken = response.data.access_token;
      localStorage.setItem(`${siteId}_access_token`, accessToken);
      props.navigation(`/${siteId}/home`);
    } catch (error) {
      console.error(error);
      localStorage.removeItem(`${siteId}_access_token`);
    }
  };

  const toRegistration = () => {
    props.changeComponent(1);
  };

  return (
    <div className={style.registrationContainer}>
      <div className={style.inputWrapper}>
        <div className={style.label}>Username</div>
        <input
          className={style.input}
          value={username}
          onChange={(e) => setUsername(e.currentTarget.value)}
          placeholder="Username"
          style={{ borderColor: state.store.config.colorPrimary }}
        />
      </div>
      <div className={style.inputWrapper}>
        <div className={style.label}>Password</div>
        <input
          className={style.input}
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value)}
          placeholder="Password"
          type="password"
          style={{ borderColor: state.store.config.colorPrimary }}
        />
      </div>
      <div className={style.buttonWrapper}>
        <button
          onClick={login}
          disabled={!username || !password}
          className={style.buttonSubmit}
          style={
            !username || !password
              ? {
                  borderColor: state.store.config.colorPrimary,
                  color: state.store.config.colorPrimary,
                }
              : { backgroundColor: state.store.config.colorPrimary }
          }
        >
          LOG IN
        </button>
        <button
          onClick={toRegistration}
          className={style.buttonBack}
          style={{ borderColor: state.store.config.colorPrimary }}
        >
          SIGN UP
        </button>
      </div>
    </div>
  );
};

export default Login;
