import React, { useEffect, useState } from "react";
import style from "./AdminPage.module.css";
import { UserType } from "./GroupItem";
import axios from "axios";
import { useParams } from "react-router-dom";

type AddMemberModalPropsType = {
  show: boolean;
  handleClose: () => void;
  groupId: string;
  usersInGroup: string[] | undefined;
  refreshData: () => void;
};

const AddMembersModal: React.FC<AddMemberModalPropsType> = ({
  show,
  handleClose,
  groupId,
  refreshData,
  usersInGroup,
}) => {
  const serverURL = process.env.REACT_APP_SERVER_URL;
  const { siteId } = useParams();

  const [searchString, setSearchString] = useState("");
  const [matchingUsers, setMatchingUsers] = useState<UserType[]>([]);
  const [users, setUsers] = useState<UserType[]>([]);

  const handleSearch = (event: any) => {
    const value = event.target.value.toLowerCase();
    setSearchString(value);

    const matches = users.filter((user) =>
      user.username.toLowerCase().includes(value)
    );
    setMatchingUsers(matches);
  };

  const getUsers = async () => {
    axios
      .get(`${serverURL}/${siteId}/get-all-free-users`)
      .then((response: { data: UserType[] }) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the sources!", error);
      });
  };

  const addMemberToGroup = async (userId: string) => {
    try {
      const res = axios.post(`${serverURL}/${siteId}/add-member-to-group`, {
        group_id: groupId,
        user_id: userId,
      });
      console.log("Add member to group - " + res);
      refreshData();
    } catch (error) {
      console.error("Add member to group error - " + error);
    }
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersInGroup]);

  return (
    <div className={`${style.modal} ${show ? style.show : ""}`}>
      <div className={style.modalContent}>
        <span className={style.close} onClick={handleClose}>
          &times;
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 20,
          }}
        >
          <h2>Add member to group</h2>
          <input
            type="text"
            value={searchString}
            onChange={handleSearch}
            placeholder="Search for a username..."
            style={{
              width: "30%",
              borderRadius: 20,
              borderWidth: 1,
              borderStyle: "solid",
            }}
          />

          <div className={style.sourcesWrapper}>
            {users.length !== 0 && searchString.length === 0
              ? users.map((user: UserType, index: number) => (
                  <div className={style.uploadedFileWrapper} key={index}>
                    <p style={{ display: "flex", fontSize: 22, gap: 5 }}>
                      <img
                        style={{ height: 30 }}
                        src="/images/circle-user.svg"
                        alt="file"
                      />
                      {user.username}
                    </p>
                    <button
                      className={style.sourceDeleteButton}
                      onClick={() => addMemberToGroup(user.id)}
                    >
                      <img
                        style={{ height: 30 }}
                        src="/images/plus-square.svg"
                        alt="edit"
                      />
                    </button>
                  </div>
                ))
              : users.length !== 0 && matchingUsers.length !== 0
              ? matchingUsers.map((user: UserType, index: number) => (
                  <div className={style.uploadedFileWrapper} key={index}>
                    <p style={{ display: "flex", fontSize: 22, gap: 5 }}>
                      <img
                        style={{ height: 30 }}
                        src="/images/circle-user.svg"
                        alt="file"
                      />
                      {user.username}
                    </p>
                    <button
                      className={style.sourceDeleteButton}
                      onClick={() => addMemberToGroup(user.id)}
                    >
                      <img
                        style={{ height: 30 }}
                        src="/images/plus-square.svg"
                        alt="edit"
                      />
                    </button>
                  </div>
                ))
              : "No Users found"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMembersModal;
