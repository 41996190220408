import React from "react";
import { AccessLevelType, DocumentType } from "./DataUploadPage";
import DocumentItem from "./DocumentItem";

type DocumentItemsType = {
  sources: { doc_name: string; access_level: number }[];
  deleteFileFromDatabase: (docName: string) => void;
  accessLevels: AccessLevelType[];
  refreshData: () => void;
  type: "admin" | "user";
};

const DocumentItems: React.FC<DocumentItemsType> = ({
  sources,
  deleteFileFromDatabase,
  accessLevels,
  refreshData,
  type,
}) => {
  return (
    <>
      {sources.length !== 0
        ? sources.map((doc: DocumentType, index: number) => (
            <DocumentItem
              key={index}
              doc={doc}
              accessLevels={accessLevels}
              deleteFileFromDatabase={deleteFileFromDatabase}
              refreshData={refreshData}
              type={type}
            />
          ))
        : "Keine Daten"}
    </>
  );
};

export default DocumentItems;
