/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import style from "./NewUI.module.css";
import axios from "axios";
import { StoreContext } from "../../data/Context";
import { useNavigate, useParams } from "react-router-dom";
import { sendAnswerToDbCrawler } from "../../langchain/chains";
import { v4 } from "uuid";
import {
  addMessageToDb,
  answer_chain_crawler_llama,
  answer_chain_crawler_openai,
  answer_chain_crawler_openai_mini,
  answer_chain_openai_crawler_three_five_turbo,
  downloadDocsFromWebsite,
  memoryVectorStoreRetriever,
} from "../../langchain/CrawlerChains";
import { ChatType } from "./NewChatAndSidebar";
import AiMessage from "../common/AiMessage";

const Crawler = () => {
  const initialMessage: ChatType = {
    chat_id: "123",
    chat_name: "Chat Room",
    user_id: "user1",
    chat_content: [],
    last_changed: "",
    role: "user",
  };
  const { siteId } = useParams();

  const serverURL = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const state = useContext(StoreContext);
  const [userInput, setUserInput] = useState("");
  const [currentChat, setCurrentChat] = useState(initialMessage);
  const [selectedLLM, setSelectedLLM] = useState<string>("openai");
  const [crawlerUrl, setCrawlerUrl] = useState<string>("");
  const [isLoadiing, setIsLoading] = useState<boolean>(false);
  const [generatingInProgress, setGeneratingInProgress] =
    useState<boolean>(false);

  const saveSelectedLLM = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    localStorage.setItem("lastUsedLLM", e.currentTarget.value);
    setSelectedLLM(e.currentTarget.value);
  };

  const getSavedSelectedLLM = async () => {
    const savedLLM = localStorage.getItem("lastUsedLLM");
    savedLLM && setSelectedLLM(savedLLM);
  };

  const fetchUserData = async (): Promise<any> => {
    try {
      const response = await axios.post(
        `${serverURL}/${siteId}/get-user-data`,
        undefined,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem(`${siteId}_access_token`),
          },
        }
      );
      const userData = response.data.user_data;
      if (userData) {
        state.setStore((prevState) => ({
          ...prevState,
          userId: userData.id,
          email: userData.email,
          username: userData.username,
          chatList: userData.chats_id,
          chatInUse: userData.lastUsedChat,
          group_id: userData.group_id,
          group_role: userData.group_role,
          template_in_use: userData.template_in_use,
        }));
      }
      return response.data.user_data;
    } catch (error) {
      console.log(error);
      navigate(`/${siteId}/`);
    }
  };

  useEffect(() => {
    getSavedSelectedLLM();
    if (state.store.userId === 0) {
      fetchUserData();
    }
  }, []);

  useEffect(() => {
    createChat();
  }, []);

  const downloadDataFromWebViaLink = async () => {
    setIsLoading(true);
    await downloadDocsFromWebsite(crawlerUrl, siteId);
    setIsLoading(false);
  };

  const getPromptTemplateContent = async () => {
    try {
      if (state.store.template_in_use !== "") {
        const res = await axios.post(
          `${serverURL}/${siteId}/get-prompt-template-content-by-id`,
          { prompt_template_id: state.store.template_in_use }
        );
        return res.data.result;
      } else {
        return "";
      }
    } catch (error) {
      console.error(error);
    }
  };

  //---------------------------------------------------------------------------------------------------------------------------------------------------
  // GET ANSWER FORM LLM GET ANSWER FORM LLM GET ANSWER FORM LLM GET ANSWER FORM LLM GET ANSWER FORM LLM GET ANSWER FORM LLM GET ANSWER FORM LLM
  //---------------------------------------------------------------------------------------------------------------------------------------------------
  const getAnswer = async (
    question: string,
    convHistory: any,
    chatId: string,
    humanMessageId: string
  ) => {
    try {
      await addMessageToDb(
        question,
        currentChat.chat_id,
        humanMessageId,
        siteId
      );

      const context = await memoryVectorStoreRetriever(question);

      let selectedLLMAnswerChain =
        selectedLLM === "llama"
          ? answer_chain_crawler_llama
          : selectedLLM === "gpt-4o"
          ? answer_chain_crawler_openai
          : selectedLLM === "gpt-4o-mini"
          ? answer_chain_crawler_openai_mini
          : selectedLLM === "gpt-3.5-turbo"
          ? answer_chain_openai_crawler_three_five_turbo
          : answer_chain_crawler_llama;

      const promptTemplateContent = await getPromptTemplateContent();

      let serverUrlForChain: string;
      let siteIdForChain: string;

      serverURL !== undefined
        ? (serverUrlForChain = serverURL)
        : (serverUrlForChain = "");

      siteId !== undefined ? (siteIdForChain = siteId) : (siteIdForChain = "");
      const response = await selectedLLMAnswerChain.stream({
        question: question,
        context: JSON.stringify(context),
        conv_history: JSON.stringify(convHistory),
        promptTemplateContent: promptTemplateContent,
        serverURL: serverUrlForChain,
        siteId: siteIdForChain,
      });
      const chunks = [];

      for await (const chunk of response) {
        chunks.push(chunk);
        setCurrentChat((prevCurrentChat) => {
          const updatedChatContent = [...prevCurrentChat.chat_content];
          const lastIndex = updatedChatContent.length - 1;

          updatedChatContent[lastIndex] = {
            ...updatedChatContent[lastIndex],
            text: (updatedChatContent[lastIndex].text || "") + chunk,
          };

          return { ...prevCurrentChat, chat_content: updatedChatContent };
        });
      }

      return chunks;
    } catch (error) {
      console.log("getAnswerChain error - " + error);
    }
  };

  //---------------------------------------------------------------------------------------------------------------------------------------------------
  // GET ANSWER WRAPPER FUNCTION WHICH SENDS FULL ANSWER TO DATABASE GET ANSWER WRAPPER FUNCTION WHICH SENDS FULL ANSWER TO DATABASE
  //---------------------------------------------------------------------------------------------------------------------------------------------------
  const fetchAnswer = async (
    question: string,
    chatContent: { text?: any; sender?: string | undefined }[],
    chatId: string,
    aiMessageId: string,
    humanMessageId: string
  ) => {
    try {
      const answer = await getAnswer(
        question,
        chatContent,
        chatId,
        humanMessageId
      );
      const combinedChunks = answer?.join("");

      await sendAnswerToDbCrawler(combinedChunks, chatId, siteId, aiMessageId);
    } catch (error) {
      console.error("Error fetching answer:", error);
      throw error;
    }
  };

  //---------------------------------------------------------------------------------------------------------------------------------------------------
  // UPDATE CHAT NAME UPDATE CHAT NAME UPDATE CHAT NAME UPDATE CHAT NAME UPDATE CHAT NAME UPDATE CHAT NAME UPDATE CHAT NAME UPDATE CHAT NAME
  //---------------------------------------------------------------------------------------------------------------------------------------------------
  const updateChatName = async (chatName: string) => {
    const payload = {
      chat_id: currentChat.chat_id,
      chat_name: chatName,
    };
    try {
      await axios.post(`${serverURL}/${siteId}/update-chat-name`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(`${siteId}_access_token`),
        },
      });
    } catch (error) {
      console.error("ERROR UPDATE CHAT NAME - " + error);
    }
  };

  //---------------------------------------------------------------------------------------------------------------------------------------------------
  // CREATE NEW CHAT CREATE NEW CHAT CREATE NEW CHAT CREATE NEW CHAT CREATE NEW CHAT CREATE NEW CHAT CREATE NEW CHAT CREATE NEW CHAT CREATE NEW CHAT
  //---------------------------------------------------------------------------------------------------------------------------------------------------
  const createChat = async () => {
    await deleteEmptyChats();
    let chatName = userInput;
    if (userInput === "") {
      chatName = "New Chat";
    }
    const payload = {
      question: chatName,
    };
    try {
      // setIsLoading(true)
      const result = await axios.post(
        `${serverURL}/${siteId}/create-new-chat`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem(`${siteId}_access_token`),
          },
        }
      );
      const newChatObj = result.data.new_chat_obj;
      setCurrentChat(newChatObj);
      fetchUserData();
      // setIsLoading(false)
      return newChatObj.chat_id;
    } catch (error) {
      console.log("Create Chat error - " + error);
    }
  };

  const deleteEmptyChats = async () => {
    try {
      await axios.get(`${serverURL}/${siteId}/delete-empty-chats`, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(`${siteId}_access_token`),
        },
      });
    } catch (error) {}
  };

  //---------------------------------------------------------------------------------------------------------------------------------------------------
  // MAIN SEND MESSAGE FUNCTION MAIN SEND MESSAGE FUNCTION MAIN SEND MESSAGE FUNCTION MAIN SEND MESSAGE FUNCTION MAIN SEND MESSAGE FUNCTION
  //---------------------------------------------------------------------------------------------------------------------------------------------------
  const progressConversation = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    questionInput: string
  ) => {
    setGeneratingInProgress(true);

    event.preventDefault();
    try {
      if (currentChat.chat_name === "New Chat") {
        updateChatName(questionInput);
      }
      setUserInput("");

      state.setStore({
        ...state.store,
        chatInUse: currentChat.chat_id,
      });

      const aiMessageId = v4();
      const humanMessageId = v4();

      const newMessages = [
        ...currentChat.chat_content,
        {
          text: questionInput,
          sender: "human",
          chat_message_id: humanMessageId,
        },
        { text: "", sender: "ai", chat_message_id: aiMessageId },
      ];

      const updatedMessagesArray = {
        ...currentChat,
        chat_content: newMessages,
      };

      setCurrentChat(updatedMessagesArray);

      await fetchAnswer(
        questionInput,
        newMessages,
        currentChat.chat_id,
        aiMessageId,
        humanMessageId
      );
      await fetchUserData();
      setGeneratingInProgress(false);
    } catch (error) {
      setGeneratingInProgress(false);

      console.log("Error in progressConversation:", error);
    }
  };

  //---------------------------------------------------------------------------------------------------------------------------------------------------
  // SET COMMENT RATING SET COMMENT RATING SET COMMENT RATING SET COMMENT RATING SET COMMENT RATING SET COMMENT RATING SET COMMENT RATING SET COMMENT R
  //---------------------------------------------------------------------------------------------------------------------------------------------------

  const setRatingToMessage = async (
    rating: string,
    messageId: string | undefined
  ) => {
    try {
      setCurrentChat((prevCurrentChat) => {
        return {
          ...prevCurrentChat,
          chat_content: prevCurrentChat.chat_content.map((message) =>
            message.chat_message_id === messageId
              ? { ...message, rating: rating }
              : message
          ),
        };
      });

      await axios.post(
        `${serverURL}/${siteId}/set-message-rating`,
        {
          chat_id: currentChat.chat_id,
          rating: rating,
          message_id: messageId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem(`${siteId}_access_token`),
          },
        }
      );
    } catch (error) {}
  };

  const setCommentToMessage = async (
    comment: string | undefined,
    messageId: string | undefined
  ) => {
    try {
      setCurrentChat((prevCurrentChat) => {
        return {
          ...prevCurrentChat,
          chat_content: prevCurrentChat.chat_content.map((message) =>
            message.chat_message_id === messageId
              ? { ...message, comment: comment }
              : message
          ),
        };
      });

      await axios.post(
        `${serverURL}/${siteId}/set-message-comment`,
        {
          chat_id: currentChat.chat_id,
          comment: comment,
          message_id: messageId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem(`${siteId}_access_token`),
          },
        }
      );
    } catch (error) {}
  };

  return (
    <div className={style.sidebarAndChatWrapper}>
      <div className={style.sidebarWrapper} style={{ paddingLeft: 0 }}>
        <input
          className={style.newChatBtn}
          style={{ padding: 5, height: 30 }}
          placeholder="Enter URL here:"
          value={crawlerUrl}
          onChange={(e) => setCrawlerUrl(e.currentTarget.value)}
        />
        <button
          onClick={downloadDataFromWebViaLink}
          className={style.newChatBtn}
        >
          {isLoadiing ? "Loading..." : "Download data via link"}
        </button>
        <button className={style.newChatBtn} onClick={createChat}>
          New Chat
        </button>
        <select
          id="llm-select"
          value={selectedLLM}
          onChange={(e) => saveSelectedLLM(e)}
          className={style.llmSelect}
          style={{ marginRight: 10 }}
        >
          <option value={"llama"}>Ollama: Llama 3.1</option>
          <option value={"gpt-4o"}>OpenAI: gpt-4o</option>
          <option value={"gpt-4o-mini"}>OpenAI: gpt-4o-mini</option>
          <option value={"gpt-3.5-turbo"}>OpenAI: gpt-3.5-turbo</option>
        </select>
      </div>
      <div className={style.chatWrapper}>
        <div className={style.chatMessagesWrapper}>
          {currentChat.chat_content.map((message, index) =>
            message.sender === "ai" ? (
              <AiMessage
                key={index}
                message={message}
                index={index}
                currentChat={currentChat}
                setRatingToMessage={setRatingToMessage}
                setCommentToMessage={setCommentToMessage}
                generatingInProgress={generatingInProgress}
              />
            ) : (
              <div
                key={index}
                className="message-wrapper"
                style={{ justifyContent: "flex-end" }}
              >
                <div key={index} className={`speech speech-human`}>
                  {message.text !== "" ? (
                    <div
                      className="answer-box"
                      style={{ color: "grey", fontWeight: 600 }}
                      dangerouslySetInnerHTML={{ __html: message.text }}
                    />
                  ) : (
                    <div className="loader" />
                  )}
                </div>
              </div>
            )
          )}
        </div>

        <form id="form" className={style.inputWrapper}>
          <input
            name="user-input"
            type="text"
            id="user-input"
            required
            value={userInput}
            onChange={(e) => setUserInput(e.currentTarget.value)}
            placeholder="Schreibe eine Nachricht"
            style={{
              borderBottomLeftRadius: 50,
              borderTopLeftRadius: 50,
              backgroundColor: "#d3d3d345",
              borderColor: "#d3d3d345",
            }}
          />
          <button
            disabled={!userInput.trim() || generatingInProgress}
            id="submit-btn"
            className="submit-btn"
            onClick={(e) => progressConversation(e, userInput)}
            style={{
              borderBottomRightRadius: 50,
              borderTopRightRadius: 50,
              backgroundColor: "#d3d3d345",
              borderColor: "#d3d3d345",
            }}
          >
            <img src="/images/send.svg" className="send-btn-icon" alt="" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default Crawler;
