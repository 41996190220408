import React, { useEffect, useState } from "react";
import style from "./NewUI.module.css";
import { ChatType } from "./NewChatAndSidebar";
import { PromptTemplate } from "@langchain/core/prompts";
import { StringOutputParser } from "@langchain/core/output_parsers";
import { JsonOutputParser } from "@langchain/core/output_parsers";
import { llmOpenAI, llmOpenAImini } from "../../langchain/chains";
import axios from "axios";
import { useParams } from "react-router-dom";

const initialMessage: ChatType = {
  chat_id: "123",
  chat_name: "Chat Room",
  user_id: "user1",
  chat_content: [],
  last_changed: "",
  role: "user",
};

type AskCRMSQLPropsType = {
  sqlDatabaseName: string;
};

const AskCRMSQL: React.FC<AskCRMSQLPropsType> = ({ sqlDatabaseName }) => {
  const { siteId } = useParams();

  const serverURL = process.env.REACT_APP_SERVER_URL;
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [currentChat, setCurrentChat] = useState(initialMessage);
  const [userInput, setUserInput] = useState("");
  const [filteredItems, setFilteredItems] = useState<any[]>([]);

  useEffect(() => {
    setCurrentChat(initialMessage);
    setFilteredItems([]);
  }, [sqlDatabaseName]);

  const checkDoAnotherFiltering = async (question: string) => {
    const prompt = `Analyze user input and try to catch words that means that User want's to do another customer finding. 
    Sometimes user can ask question to already filtered customers and it can look like user want's to filter again, so be careful.
    Return JSON object with key "result" and only true or false as value.
    Question: {question}    
        `;
    const promptText = PromptTemplate.fromTemplate(prompt);

    const answer_chain_find_filter_values = promptText
      .pipe(llmOpenAImini)
      .pipe(new JsonOutputParser());

    const resRaw: { result: boolean } =
      await answer_chain_find_filter_values.invoke({
        question: question,
      });

    return resRaw.result;
  };

  const analyzeData = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    question: string
  ) => {
    event.preventDefault();
    setUserInput("");

    setInProgress(true);

    const structurizationPrompt = `Write structurized answer for user's question for our CRM
     system. Question: {question}. System will get result for user's question: {info}.
     Answer must be in German Language. Wrap answer with HTML-elements, do not wrap with html block`;
    const structurizationPromptText = PromptTemplate.fromTemplate(
      structurizationPrompt
    );

    const structurizationAnswer_chain = structurizationPromptText
      .pipe(llmOpenAI)
      .pipe(new StringOutputParser());

    try {
      setCurrentChat((prevCurrentChat) => ({
        ...prevCurrentChat,
        chat_content: [{ text: "", sender: "ai" }],
      }));

      const res = await axios.post(`${serverURL}/${siteId}/ask-database`, {
        question: question,
        sql_db_name: sqlDatabaseName,
      });

      console.log(res.data.result);
      setFilteredItems(res.data.result);
      const response = await structurizationAnswer_chain.stream({
        question: question,
        info: JSON.stringify(res.data.result),
      });

      for await (const chunk of response) {
        setCurrentChat((prevCurrentChat) => {
          const updatedChatContent = [...prevCurrentChat.chat_content];
          const lastIndex = updatedChatContent.length - 1;

          updatedChatContent[lastIndex] = {
            ...updatedChatContent[lastIndex],
            text: (updatedChatContent[lastIndex].text || "") + chunk,
          };

          return {
            ...prevCurrentChat,
            chat_content: updatedChatContent,
          };
        });
      }
      setInProgress(false);
    } catch (error) {
      setInProgress(false);
      console.error("Error during data analysis:", error);
      setCurrentChat((prevCurrentChat) => ({
        ...prevCurrentChat,
        chat_content: [
          ...prevCurrentChat.chat_content,
          { text: "Error analyzing data.", sender: "ai" },
        ],
      }));
    }
  };

  const getAnswer = async (question: string) => {
    try {
      const structurizationPrompt = `Write structurized answer for user question about received data from our CRM system based on provided question and information.
                Answer must be in German Language.
                Wrap answer with HTML-elements, do not wrap with html block

                Question: {question}
                Customers to work with; {customers}
                `;
      const structurizationPromptText = PromptTemplate.fromTemplate(
        structurizationPrompt
      );

      const structurizationAnswer_chain = structurizationPromptText
        .pipe(llmOpenAI)
        .pipe(new StringOutputParser());

      const response = await structurizationAnswer_chain.stream({
        question: question,
        customers: JSON.stringify(filteredItems),
      });
      const chunks = [];

      for await (const chunk of response) {
        chunks.push(chunk);
        setCurrentChat((prevCurrentChat) => {
          const updatedChatContent = [...prevCurrentChat.chat_content];
          const lastIndex = updatedChatContent.length - 1;

          updatedChatContent[lastIndex] = {
            ...updatedChatContent[lastIndex],
            text: (updatedChatContent[lastIndex].text || "") + chunk,
          };

          return { ...prevCurrentChat, chat_content: updatedChatContent };
        });
      }

      return chunks;
    } catch (error) {
      console.log("getAnswerChain error - " + error);
    }
  };

  const progressConversation = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    questionInput: string
  ) => {
    event.preventDefault();
    try {
      setUserInput("");
      setInProgress(true);

      const isAnotherFiltering = await checkDoAnotherFiltering(questionInput);

      if (isAnotherFiltering) {
        await analyzeData(event, questionInput);
      } else {
        const newMessages = [
          ...currentChat.chat_content,
          { text: questionInput, sender: "human" },
          { text: "", sender: "ai" },
        ];

        const updatedMessagesArray = {
          ...currentChat,
          chat_content: newMessages,
        };

        setCurrentChat(updatedMessagesArray);

        await getAnswer(questionInput);
      }
      setInProgress(false);
    } catch (error) {
      console.log("Error in progressConversation:", error);
      setInProgress(false);
    }
  };

  return (
    <>
      <div className={style.chatWrapper}>
        <div
          className={style.chatMessagesWrapper}
          style={{ height: "100%", maxHeight: "100%" }}
        >
          {currentChat.chat_content.map((message, index) =>
            message.sender === "ai" ? (
              <div
                key={index}
                className="message-wrapper"
                style={{ justifyContent: "flex-start" }}
              >
                <div className={style.gIconTemp}>G</div>
                <div key={index} className={`speech speech-ai`}>
                  {message.text !== "" ? (
                    <div
                      className="answer-box"
                      style={{ color: "grey", fontWeight: 600 }}
                      dangerouslySetInnerHTML={{ __html: message.text }}
                    />
                  ) : (
                    <div className="loader" />
                  )}
                </div>
              </div>
            ) : (
              <div
                key={index}
                className="message-wrapper"
                style={{ justifyContent: "flex-end" }}
              >
                <div key={index} className={`speech speech-human`}>
                  {message.text !== "" ? (
                    <div
                      className="answer-box"
                      style={{ color: "grey", fontWeight: 600 }}
                      dangerouslySetInnerHTML={{ __html: message.text }}
                    />
                  ) : (
                    <div className="loader" />
                  )}
                </div>
              </div>
            )
          )}
        </div>

        <form id="form" className={style.inputWrapper}>
          <input
            name="user-input"
            type="text"
            id="user-input"
            required
            value={userInput}
            onChange={(e) => setUserInput(e.currentTarget.value)}
            placeholder="Schreibe eine Nachricht"
            style={{
              borderBottomLeftRadius: 50,
              borderTopLeftRadius: 50,
              backgroundColor: "#d3d3d345",
              borderColor: "#d3d3d345",
            }}
          />
          <button
            disabled={inProgress || !userInput.trim()}
            id="submit-btn"
            className="submit-btn"
            onClick={(e) =>
              filteredItems.length === 0
                ? analyzeData(e, userInput)
                : progressConversation(e, userInput)
            }
            style={{
              borderBottomRightRadius: 50,
              borderTopRightRadius: 50,
              backgroundColor: "#d3d3d345",
              borderColor: "#d3d3d345",
              cursor: "pointer",
            }}
          >
            <img src="/images/send.svg" className="send-btn-icon" alt="" />
          </button>
        </form>
      </div>
    </>
  );
};

export default AskCRMSQL;
