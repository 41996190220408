import React, { useContext, useEffect, useState } from "react";
import style from "./NewUI.module.css";
import DocumentsList from "./DocumentsList";
import DataUploadMenu from "./DataUploadMenu";
import { AccessLevelType } from "../AdminPage/DataUploadPage";
import axios from "axios";
import { useParams } from "react-router-dom";
import { StoreContext } from "../../data/Context";

const NewDataUpload = () => {
  const serverURL = process.env.REACT_APP_SERVER_URL;
  const { siteId } = useParams();
  const state = useContext(StoreContext);

  const [accessLevels, setAccessLevels] = useState<AccessLevelType[]>([]);
  const [selectedSidebarItem, setSelectedSidebarItem] =
    useState<string>("Dokumentenliste");
  const sidebarItems = ["Dokumentenliste", "Dokumente hochladen"];

  const [sources, setSources] = useState([]);

  const getAccessLevels = async () => {
    try {
      const res = await axios.get(`${serverURL}/${siteId}/get-access-levels`);
      setAccessLevels(res.data.result);
    } catch (error) {
      console.log("Error while getAccessLevelsNames " + error);
    }
  };

  const getSources = async () => {
    axios
      .get(`${serverURL}/${siteId}/sources`)
      .then((response) => {
        setSources(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the sources!", error);
      });
  };

  const refreshData = async () => {
    await getSources();
    await getAccessLevels();
  };
  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={style.sidebarAndChatWrapper}>
      <div className={style.sidebarWrapper}>
        {sidebarItems.map((itemName: string, index: number) => (
          <span
            key={index}
            className={
              selectedSidebarItem === itemName
                ? style.sidebarItemSelected
                : style.sidebarItem
            }
            style={
              selectedSidebarItem === itemName
                ? {
                    color: state.store.config.colorPrimary,
                    borderRightColor: state.store.config.colorPrimary,
                  }
                : {}
            }
            onClick={() => setSelectedSidebarItem(itemName)}
          >
            {itemName}
          </span>
        ))}
      </div>

      <div className={style.chatWrapper}>
        {selectedSidebarItem === "Dokumentenliste" ? (
          <DocumentsList
            accessLevels={accessLevels}
            sources={sources}
            refreshData={refreshData}
          />
        ) : (
          <DataUploadMenu
            refreshData={refreshData}
            accessLevels={accessLevels}
          />
        )}
      </div>
    </div>
  );
};

export default NewDataUpload;
