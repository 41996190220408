import { PromptTemplate } from '@langchain/core/prompts';

const standalone_question_template = `Given some conversation history (if any) and a question, convert the question to a standalone question. 
  give direct standalone question. Standalone question must be in German language.
  conversation history: {conv_history}
  question: {question} 
  standalone question:
`

const answer_template = `
You are a helpful German-speaking support bot that can answer a given question based on the context provided (if any).
Use the German language to formulate the answer.
Format the answer with HTML elements.
You can use: <div></div>, <ul></ul>, <p></p>, <li></li>, <strong></strong>, <h2></h2> <br/> in answer.
{promptTemplateContent}

When searching for information on the Internet, add the source in the format:
<p><strong>Quelle:</strong>  <a
              target='_blank'
              rel='noreferrer'
              href='(link)'
            >(site name)</a></p>

Context: {context}
Conversation history: {conv_history}
Question: {question}
Answer:
`

const standalone_question_prompt = PromptTemplate.fromTemplate(standalone_question_template)

const answer_prompt = PromptTemplate.fromTemplate(answer_template)

export {standalone_question_template, answer_template, standalone_question_prompt, answer_prompt}