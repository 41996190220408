export const mockData = [
    {"Name": "Müller", "Vorname": "Julian", "Gliederung": "Brandenburg", "Mitgliedschaft": "ja", "Letzter Kontakt": "21.01.2023", "Grund": "Kündigung", "Geburtstag": "25.01.1950", "Firma": "TechSolutions GmbH", "e-Mail": "julian.müller@business.net"},
    {"Name": "Becker", "Vorname": "Paul", "Gliederung": "Bayern", "Mitgliedschaft": "nein", "Letzter Kontakt": "01.10.2019", "Grund": "Adressdaten ändern", "Geburtstag": "26.01.1990", "Firma": "Innovative Systems AG", "e-Mail": "paul.becker@company.org"},
    {"Name": "Schulz", "Vorname": "Emma", "Gliederung": "Baden-Württemberg", "Mitgliedschaft": "ja", "Letzter Kontakt": "11.09.2018", "Grund": "Anfrage Beratung", "Geburtstag": "01.07.1966", "Firma": "Meyer & Partner Consulting", "e-Mail": "emma.schulz@mail.de"},
    {"Name": "Müller", "Vorname": "Tim", "Gliederung": "Sachsen", "Mitgliedschaft": "nein", "Letzter Kontakt": "21.04.2022", "Grund": "Adressdaten ändern", "Geburtstag": "11.08.1968", "Firma": "GreenWave Technologies", "e-Mail": "tim.müller@example.com"},
    {"Name": "Schneider", "Vorname": "Marie", "Gliederung": "Rheinland-Pfalz", "Mitgliedschaft": "nein", "Letzter Kontakt": "25.04.2021", "Grund": "Beleg angefordert", "Geburtstag": "11.10.1998", "Firma": "Global Trade Solutions", "e-Mail": "marie.schneider@company.org" },
    {"Name": "Weber", "Vorname": "Anna", "Gliederung": "Brandenburg", "Mitgliedschaft": "ja", "Letzter Kontakt": "04.02.2021", "Grund": "Adressdaten ändern", "Geburtstag": "31.08.1954", "Firma": "Nordic Financial Services", "e-Mail": "anna.weber@mail.de"},
    {"Name": "Weber", "Vorname": "Sophie", "Gliederung": "Berlin", "Mitgliedschaft": "ja", "Letzter Kontakt": "26.06.2022", "Grund": "Bankdaten geändert", "Geburtstag": "03.07.1990", "Firma": "Precision Engineering", "e-Mail": "sophie.weber@company.org"},
    {"Name": "Schmidt", "Vorname": "Sophie", "Gliederung": "Hamburg", "Mitgliedschaft": "ja", "Letzter Kontakt": "06.12.2018", "Grund": "Bankdaten geändert", "Geburtstag": "13.06.1953", "Firma": "FutureVision Media", "e-Mail": "sophie.schmidt@mail.de"},
    {"Name": "Müller", "Vorname": "Marie", "Gliederung": "Sachsen", "Mitgliedschaft": "nein", "Letzter Kontakt": "14.10.2020", "Grund": "Adressdaten ändern", "Geburtstag": "07.06.1955", "Firma": "AlphaMarkt GmbH", "e-Mail": "marie.müller@mail.de"},
    {"Name": "Schmidt", "Vorname": "Julian", "Gliederung": "Hamburg", "Mitgliedschaft": "ja", "Letzter Kontakt": "04.09.2022", "Grund": "Bankdaten geändert", "Geburtstag": "06.07.1989", "Firma": "Urban Development Corp.", "e-Mail": "julian.schmidt@business.net"},
    {"Name": "Wagner", "Vorname": "Sophie", "Gliederung": "Hamburg", "Mitgliedschaft": "nein", "Letzter Kontakt": "10.02.2018", "Grund": "Anfrage Beratung", "Geburtstag": "19.04.1969", "Firma": "EcoPower Renewables", "e-Mail": "sophie.wagner@company.org"},
    {"Name": "Müller", "Vorname": "Lukas", "Gliederung": "Hessen", "Mitgliedschaft": "ja", "Letzter Kontakt": "29.02.2024", "Grund": "Adressdaten ändern", "Geburtstag": "26.08.1983", "Firma": "DigitalCraft Studio", "e-Mail": "lukas.müller@company.org"},
    {"Name": "Müller", "Vorname": "Max", "Gliederung": "Brandenburg", "Mitgliedschaft": "ja", "Letzter Kontakt": "29.08.2020", "Grund": "Kündigung", "Geburtstag": "30.07.1995", "Firma": "BlueSky Logistics", "e-Mail": "max.müller@company.org"},
    {"Name": "Fischer", "Vorname": "Julian", "Gliederung": "Hessen", "Mitgliedschaft": "ja", "Letzter Kontakt": "10.04.2021", "Grund": "Bankdaten geändert", "Geburtstag": "15.07.1969", "Firma": "NextGen Pharmaceuticals", "e-Mail": "julian.fischer@mail.de"},
    {"Name": "Becker", "Vorname": "Emma", "Gliederung": "Hamburg", "Mitgliedschaft": "ja", "Letzter Kontakt": "26.12.2019", "Grund": "Beleg angefordert", "Geburtstag": "02.04.1960", "Firma": "Quantum Dynamics", "e-Mail": "emma.becker@mail.de"},
    {"Name": "Meyer", "Vorname": "Max", "Gliederung": "Hamburg", "Mitgliedschaft": "nein", "Letzter Kontakt": "28.02.2019", "Grund": "Beleg angefordert", "Geburtstag": "12.05.1960", "Firma": "SecureData Systems", "e-Mail": "max.meyer@example.com" },
    {"Name": "Hoffmann", "Vorname": "Marie", "Gliederung": "Sachsen", "Mitgliedschaft": "nein", "Letzter Kontakt": "01.07.2024", "Grund": "Anfrage Beratung", "Geburtstag": "04.09.1987", "Firma": "BrightPath Education", "e-Mail": "marie.hoffmann@office.eu" },
    {"Name": "Weber", "Vorname": "Tim", "Gliederung": "Sachsen", "Mitgliedschaft": "ja", "Letzter Kontakt": "20.04.2023", "Grund": "Bankdaten geändert", "Geburtstag": "29.08.1976", "Firma": "PrimeHealth Clinics", "e-Mail": "tim.weber@company.org" },
    {"Name": "Hoffmann", "Vorname": "Max", "Gliederung": "Hessen", "Mitgliedschaft": "nein", "Letzter Kontakt": "24.11.2023", "Grund": "Adressdaten ändern", "Geburtstag": "06.10.1996", "Firma": "EcoLogic Innovations", "e-Mail": "max.hoffmann@company.org" },
    {"Name": "Schmidt", "Vorname": "Paul", "Gliederung": "Hamburg", "Mitgliedschaft": "nein", "Letzter Kontakt": "08.06.2021", "Grund": "Bankdaten geändert", "Geburtstag": "08.03.1954", "Firma": "Skyline Architects", "e-Mail": "paul.schmidt@example.com" }
]



export const mockKeys = ["Name", "Vorname", "Gliederung", "Mitgliedschaft", "Letzter Kontakt", "Grund", "Geburtstag", "Firma", "e-Mail"]

export const customersMockKeys = [
  "Kundenname",
  "Segment",
  "Land",
  "Maschinentyp",
  "Projektname",
  "Projektstartdatum",
  "Projektenddatum",
  "Projektwert (Mio. €)",
  "Kontaktperson",
  "E-Mail",
  "Telefonnummer",
  "Adresse",
  "Geologie",
  "Schilddurchmesser (mm)",
  "Antriebsleistung (kW)",
  "Drehmoment (kNm)",
  "Auftraggeber",
  "Vortriebslänge (m)",
  "Maschinenanzahl",
  "Bemerkungen"
]

export const customersMockData = [
  {
    "Kundenname": "Align JV",
    "Segment": "Baugewerbe",
    "Land": "Großbritannien",
    "Maschinentyp": "Variable-Density-TBM",
    "Projektname": "High Speed 2 Phase 1",
    "Projektstartdatum": 2021,
    "Projektenddatum": 2024,
    "Projektwert (Mio. €)": 450,
    "Kontaktperson": "Anja Heckendorf",
    "E-Mail": "anja.h@alignjv.com",
    "Telefonnummer": "+44 203 5550123",
    "Adresse": "123 Baker St, London",
    "Geologie": "Kalkstein",
    "Schilddurchmesser (mm)": 10240,
    "Antriebsleistung (kW)": 3500,
    "Drehmoment (kNm)": 16350,
    "Auftraggeber": "Bouygues Travaux",
    "Vortriebslänge (m)": 16046,
    "Maschinenanzahl": 2,
    "Bemerkungen": "Durchbruch erreicht"
  },
  {
    "Kundenname": "Alpha Constructions",
    "Segment": "Bau",
    "Land": "Deutschland",
    "Maschinentyp": "Schildvortriebsmaschine",
    "Projektname": "Berliner U-Bahn",
    "Projektstartdatum": 2022,
    "Projektenddatum": 2026,
    "Projektwert (Mio. €)": 320,
    "Kontaktperson": "Jan Müller",
    "E-Mail": "jmuller@alphacons.de",
    "Telefonnummer": "+49 030 9876543",
    "Adresse": "Hauptstraße 5, Berlin",
    "Geologie": "Ton",
    "Schilddurchmesser (mm)": 6000,
    "Antriebsleistung (kW)": 2500,
    "Drehmoment (kNm)": 8000,
    "Auftraggeber": "Stadt Berlin",
    "Vortriebslänge (m)": 5500,
    "Maschinenanzahl": 1,
    "Bemerkungen": "Im Zeitplan"
  },
  {
    "Kundenname": "Beta Engineering",
    "Segment": "Infrastruktur",
    "Land": "Italien",
    "Maschinentyp": "Hard-Rock-TBM",
    "Projektname": "Rom Metro Erweiterung",
    "Projektstartdatum": 2023,
    "Projektenddatum": 2028,
    "Projektwert (Mio. €)": 750,
    "Kontaktperson": "Luca Rossi",
    "E-Mail": "luca.rossi@betaeng.it",
    "Telefonnummer": "+39 06 12345678",
    "Adresse": "Via Roma 10, Rom",
    "Geologie": "Basalt",
    "Schilddurchmesser (mm)": 8000,
    "Antriebsleistung (kW)": 3000,
    "Drehmoment (kNm)": 12000,
    "Auftraggeber": "Metro Rom",
    "Vortriebslänge (m)": 8300,
    "Maschinenanzahl": 1,
    "Bemerkungen": "Unter Budget"
  },
  {
    "Kundenname": "Gamma Construction",
    "Segment": "Verkehr",
    "Land": "USA",
    "Maschinentyp": "EPB-TBM",
    "Projektname": "New York Subway Line",
    "Projektstartdatum": 2020,
    "Projektenddatum": 2023,
    "Projektwert (Mio. €)": 1200,
    "Kontaktperson": "Mike Johnson",
    "E-Mail": "mikej@gammacons.com",
    "Telefonnummer": "+1 212 5550192",
    "Adresse": "420 5th Ave, NY",
    "Geologie": "Schiefer",
    "Schilddurchmesser (mm)": 9500,
    "Antriebsleistung (kW)": 2800,
    "Drehmoment (kNm)": 15000,
    "Auftraggeber": "City of New York",
    "Vortriebslänge (m)": 13600,
    "Maschinenanzahl": 3,
    "Bemerkungen": "Vorzeitiger Abschluss"
  },
  {
    "Kundenname": "Delta Tunnels",
    "Segment": "Bergbau",
    "Land": "Australien",
    "Maschinentyp": "Double Shield TBM",
    "Projektname": "Perth Gold Mine",
    "Projektstartdatum": 2022,
    "Projektenddatum": 2027,
    "Projektwert (Mio. €)": 500,
    "Kontaktperson": "Fiona White",
    "E-Mail": "fwhite@deltatun.au",
    "Telefonnummer": "+61 8 9123 4567",
    "Adresse": "50 King St, Perth",
    "Geologie": "Goldlager",
    "Schilddurchmesser (mm)": 4000,
    "Antriebsleistung (kW)": 1800,
    "Drehmoment (kNm)": 7000,
    "Auftraggeber": "GoldCorp Australia",
    "Vortriebslänge (m)": 3100,
    "Maschinenanzahl": 2,
    "Bemerkungen": "Neue Lagerstätten entdeckt"
  },
  {
    "Kundenname": "Epsilon Builders",
    "Segment": "Wasserwirtschaft",
    "Land": "Indien",
    "Maschinentyp": "Slurry TBM",
    "Projektname": "Mumbai Water Supply",
    "Projektstartdatum": 2021,
    "Projektenddatum": 2025,
    "Projektwert (Mio. €)": 410,
    "Kontaktperson": "Ravi Sharma",
    "E-Mail": "ravi@epsilon.in",
    "Telefonnummer": "+91 22 5550123",
    "Adresse": "Gateway of India, Mumbai",
    "Geologie": "Sandstein",
    "Schilddurchmesser (mm)": 5000,
    "Antriebsleistung (kW)": 2000,
    "Drehmoment (kNm)": 8000,
    "Auftraggeber": "Mumbai Water Board",
    "Vortriebslänge (m)": 5000,
    "Maschinenanzahl": 1,
    "Bemerkungen": "Wasseraufbereitungsstart"
  },
  {
    "Kundenname": "Theta Ltd",
    "Segment": "Energie",
    "Land": "Norwegen",
    "Maschinentyp": "Box Jacking Machine",
    "Projektname": "Oslo Wind Farm",
    "Projektstartdatum": 2020,
    "Projektenddatum": 2025,
    "Projektwert (Mio. €)": 330,
    "Kontaktperson": "Ole Nordmann",
    "E-Mail": "ole.nordmann@thetaltd.no",
    "Telefonnummer": "+47 22 5550123",
    "Adresse": "Karl Johans gate, Oslo",
    "Geologie": "Lehm",
    "Schilddurchmesser (mm)": 3000,
    "Antriebsleistung (kW)": 1500,
    "Drehmoment (kNm)": 4000,
    "Auftraggeber": "Norwegian Energy",
    "Vortriebslänge (m)": 3000,
    "Maschinenanzahl": 1,
    "Bemerkungen": "Erste Windturbine installiert"
  },
  {
    "Kundenname": "Iota Industries",
    "Segment": "Herstellung",
    "Land": "Japan",
    "Maschinentyp": "Pipe Jacking Machine",
    "Projektname": "Tokyo Conveyor System",
    "Projektstartdatum": 2023,
    "Projektenddatum": 2026,
    "Projektwert (Mio. €)": 590,
    "Kontaktperson": "Hiroshi Tanaka",
    "E-Mail": "hiroshi@iotaind.jp",
    "Telefonnummer": "+81 3 5550123",
    "Adresse": "Shinjuku, Tokyo",
    "Geologie": "Granit",
    "Schilddurchmesser (mm)": 2000,
    "Antriebsleistung (kW)": 1000,
    "Drehmoment (kNm)": 3000,
    "Auftraggeber": "Japanese Gov",
    "Vortriebslänge (m)": 2500,
    "Maschinenanzahl": 1,
    "Bemerkungen": "Automatisierung fertiggestellt"
  },
  {
    "Kundenname": "Kappa Building Co",
    "Segment": "Bau",
    "Land": "Südafrika",
    "Maschinentyp": "Segmental Liner Machine",
    "Projektname": "Johannesburg Mall",
    "Projektstartdatum": 2024,
    "Projektenddatum": 2027,
    "Projektwert (Mio. €)": 370,
    "Kontaktperson": "Jacob Zuma",
    "E-Mail": "jacob.zuma@kappabc.za",
    "Telefonnummer": "+27 11 5550123",
    "Adresse": "Mandela Square, Johannesburg",
    "Geologie": "Marmor",
    "Schilddurchmesser (mm)": 5000,
    "Antriebsleistung (kW)": 1600,
    "Drehmoment (kNm)": 5000,
    "Auftraggeber": "South African Mall",
    "Vortriebslänge (m)": 4000,
    "Maschinenanzahl": 1,
    "Bemerkungen": "Eröffnung verzögert"
    },
    {
    "Kundenname": "Zeta Corp",
    "Segment": "Pharmazeutisch",
    "Land": "Schweiz",
    "Maschinentyp": "Slurry TBM",
    "Projektname": "Zurich BioLabs",
    "Projektstartdatum": 2022,
    "Projektenddatum": 2025,
    "Projektwert (Mio. €)": 660,
    "Kontaktperson": "Hans Zimmer",
    "E-Mail": "zimmer@zetacorp.ch",
    "Telefonnummer": "+41 44 5550123",
    "Adresse": "Bahnhofstrasse 1, Zurich",
    "Geologie": "Kalkstein",
    "Schilddurchmesser (mm)": 7500,
    "Antriebsleistung (kW)": 2200,
    "Drehmoment (kNm)": 8500,
    "Auftraggeber": "Swiss Biotech",
    "Vortriebslänge (m)": 7000,
    "Maschinenanzahl": 1,
    "Bemerkungen": "Forschungsfortschritt"
    },
      {
    "Kundenname": "Eta Infrastructure",
    "Segment": "Öffentliche Arbeiten",
    "Land": "Frankreich",
    "Maschinentyp": "Mixshield TBM",
    "Projektname": "Paris Water System",
    "Projektstartdatum": 2019,
    "Projektenddatum": 2023,
    "Projektwert (Mio. €)": 420,
    "Kontaktperson": "Claude Monet",
    "E-Mail": "monet@etafr.fr",
    "Telefonnummer": "+33 1 5550101",
    "Adresse": "Rue de Rivoli, Paris",
    "Geologie": "Sandstein",
    "Schilddurchmesser (mm)": 6500,
    "Antriebsleistung (kW)": 2400,
    "Drehmoment (kNm)": 9000,
    "Auftraggeber": "Paris Municipality",
    "Vortriebslänge (m)": 5000,
    "Maschinenanzahl": 1,
    "Bemerkungen": "Wasseraufbereitungsstart"
  }
]
