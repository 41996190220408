import React, { useContext, useState } from "react";
import style from "./NewUI.module.css";
import SurveyAnalysis from "./SurveyAnalysis";
import AskCRMMembers from "./AskCRMMembers";
import AskCRMCustomers from "./AskCRMCustomers";
import { StoreContext } from "../../data/Context";

const TasksLibrary = () => {
  const tasks = [
    "Umfrageanalyse",
    "Frag das CRM (Kunden)",
    "Frag das CRM (Mitglieder)",
  ];
  const [selectedTask, setSelectedTask] = useState(tasks[0]);
  const state = useContext(StoreContext);

  return (
    <div className={style.sidebarAndChatWrapper}>
      <div className={style.sidebarWrapper}>
        {tasks.map((task: string, index: number) => (
          <span
            key={index}
            onClick={() => setSelectedTask(task)}
            className={
              selectedTask === task
                ? style.sidebarItemSelected
                : style.sidebarItem
            }
            style={
              selectedTask === task
                ? {
                    color: state.store.config.colorPrimary,
                    borderRightColor: state.store.config.colorPrimary,
                  }
                : {}
            }
          >
            {task}
          </span>
        ))}
      </div>

      <>
        {selectedTask === "Umfrageanalyse" ? (
          <SurveyAnalysis />
        ) : selectedTask === "Frag das CRM (Mitglieder)" ? (
          <AskCRMMembers />
        ) : (
          <AskCRMCustomers />
        )}
      </>
    </div>
  );
};

export default TasksLibrary;
