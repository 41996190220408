import React, { useCallback, useContext, useState } from "react";
import { AccessLevelType } from "../AdminPage/DataUploadPage";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import style from "../AdminPage/AdminPage.module.css";
import { useParams } from "react-router-dom";
import { StoreContext } from "../../data/Context";

type DataUploadMenuType = {
  refreshData: () => void;
  accessLevels: AccessLevelType[];
};

const DataUploadMenu: React.FC<DataUploadMenuType> = ({
  refreshData,
  accessLevels,
}) => {
  const serverURL = process.env.REACT_APP_SERVER_URL;
  const { siteId } = useParams();
  const state = useContext(StoreContext);

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedAccessLevel, setSelectedAccessLevel] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setSelectedFiles(acceptedFiles);
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedFiles.length > 0) {
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("files", file);
      });
      formData.append("accessLevel", selectedAccessLevel.toString());
      setLoading(true);
      try {
        const response = await axios.post(
          `${serverURL}/${siteId}/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("Files uploaded successfully:", response.data);
        await refreshData();
        setSelectedFiles([]);
        setLoading(false);
      } catch (error) {
        console.error("Error uploading files:", error);
        setLoading(false);
      }
    } else {
      console.log("No files selected");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "90%",
      }}
    >
      <h1 style={{ color: state.store.config.colorPrimary }}>
        Upload Training Data
      </h1>
      <form className={style.formWrapper} onSubmit={handleSubmit}>
        <div
          {...getRootProps()}
          style={{
            border: `2px dashed ${state.store.config.colorPrimary}`,
            padding: "20px",
            textAlign: "center",
            width: "90%",
          }}
        >
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
        <div>
          {selectedFiles.length !== 0 ? (
            selectedFiles.map((file: File, index: number) => (
              <p key={index}>{file.name}</p>
            ))
          ) : (
            <p>No files selected</p>
          )}
        </div>
        <select
          id="selectDropdown"
          value={selectedAccessLevel}
          onChange={(e) =>
            setSelectedAccessLevel(Number(e.currentTarget.value))
          }
        >
          {accessLevels.length !== 0 ? (
            accessLevels.map(
              (accessLevelItem: AccessLevelType, index: number) => (
                <option key={index} value={accessLevelItem.access_level}>
                  {accessLevelItem.access_level_name}
                </option>
              )
            )
          ) : (
            <option value={0}>Option 1</option>
          )}
        </select>
        <button
          disabled={
            accessLevels.length === 0 || selectedFiles.length === 0 || loading
          }
          style={
            accessLevels.length !== 0 || selectedFiles.length !== 0 || !loading
              ? {
                  color: state.store.config.colorPrimary,
                  borderColor: state.store.config.colorPrimary,
                  cursor: "pointer",
                }
              : {}
          }
          className={style.submitBtn}
          type="submit"
        >
          {loading ? "Uploading in process..." : "Upload"}
        </button>
      </form>
    </div>
  );
};

export default DataUploadMenu;
