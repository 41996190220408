import React, { useContext, useState } from "react";
import style from "./AdminPage.module.css";
import { AccessLevelType, DocumentType } from "./DataUploadPage";
import axios from "axios";
import { useParams } from "react-router-dom";
import { StoreContext } from "../../data/Context";

type DocumentItemType = {
  doc: DocumentType;
  accessLevels: AccessLevelType[];
  deleteFileFromDatabase: (docName: string) => void;
  refreshData: () => void;
  type: "admin" | "user";
};

const DocumentItem: React.FC<DocumentItemType> = ({
  doc,
  accessLevels,
  deleteFileFromDatabase,
  refreshData,
  type,
}) => {
  const serverURL = process.env.REACT_APP_SERVER_URL;
  const { siteId } = useParams();
  const state = useContext(StoreContext);

  const [selectedAccessLevel, setSelectedAccessLevel] = useState(
    doc.access_level
  );
  const [editMode, setEditMode] = useState<boolean>(false);

  const handleSelect = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedAccessLevel(Number(e.currentTarget.value));
    setEditMode(!editMode);
    await updateAccessLevel(Number(e.currentTarget.value));
    refreshData();
  };

  const updateAccessLevel = async (newAccessLevelValue: number) => {
    try {
      const res = await axios.post(
        `${serverURL}/${siteId}/update-access-level-for-document`,
        {
          doc_name: doc.doc_name,
          access_value: newAccessLevelValue,
        }
      );
      console.log(res.data.result);
    } catch (error) {}
  };

  return (
    <div
      className={style.uploadedFileWrapper}
      style={{ borderRightColor: state.store.config.colorPrimary }}
    >
      <p
        style={{
          display: "flex",
          alignItems: "center",
          gap: 5,
          wordBreak: "break-all",
        }}
      >
        <img style={{ height: 20 }} src="/images/file-zipper.svg" alt="file" />
        {doc.doc_name.startsWith("https://") ? (
          <a
            target="_blank"
            rel="noreferrer"
            href={doc.doc_name}
            style={{ color: state.store.config.colorPrimary }}
          >
            {doc.doc_name}
          </a>
        ) : (
          <>{doc.doc_name}</>
        )}
      </p>
      <div className={style.documentItemRightPart}>
        {doc.doc_name.startsWith("https://") ? (
          <></>
        ) : (
          <a
            className={style.documentItemEditButton}
            target="_blank"
            rel="noreferrer"
            href={
              doc.doc_name.startsWith("https://")
                ? doc.doc_name
                : `${serverURL}/${siteId}/file-source/${doc.doc_name.substring(
                    8
                  )}`
            }
          >
            Öffnen
          </a>
        )}
        {type === "admin" && (
          <>
            {editMode ? (
              <select
                id="selectDropdown"
                value={selectedAccessLevel}
                onChange={(e) => handleSelect(e)}
              >
                {accessLevels.length !== 0 ? (
                  accessLevels.map(
                    (accessLevelItem: AccessLevelType, index: number) => (
                      <option key={index} value={accessLevelItem.access_level}>
                        {accessLevelItem.access_level_name}
                      </option>
                    )
                  )
                ) : (
                  <option value={0}>Option 1</option>
                )}
              </select>
            ) : (
              <button
                className={style.documentItemEditButton}
                onClick={() => setEditMode(!editMode)}
              >
                Bearbeiten
              </button>
            )}

            <button
              onClick={() => deleteFileFromDatabase(doc.doc_name)}
              className={style.sourceDeleteButton}
            >
              <img
                style={{ height: 30, cursor: "pointer" }}
                src="/images/bin.svg"
                alt="delete"
              />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default DocumentItem;
